<template>
    <div>
        <div style=" background:white;line-height:40px;"> 
            <div style="margin-top:3px;display:flex;width:1400px; padding-left:20px;">
                <i class="iconfont icon-xinzeng" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                <el-link :disabled="addBool" @click="addRec" :underline="false" style="margin-top:0px;"><span style="font-size:13px;margin-right:15px;">新增出库单</span></el-link>
                <div style="margin-right:10px;font-size:12px;margin-left:5px;">状态</div>
                <el-radio-group v-model="stfgSet"   size="small" @change="stfgChange" style="margin-top:3px;">
                    <el-radio border label="ALL"  >所有</el-radio>
                    <el-radio border label="N" >未审核</el-radio>
                    <el-radio border label="Y" >已审核</el-radio>
                    <el-radio border label="X" >已作废</el-radio>
                </el-radio-group>
                <span style=" margin-left:20px;font-size:12px;margin-right:10px;">字段查询</span>
                <el-select v-model="selparam" size="small" placeholder="请选择列名" clearable  style="width:150px; " >
                    <span v-for="item in poCol" :key='item.key'>
                        <el-option :label="item.title" :value="item.key"></el-option>
                    </span>
                </el-select>
                <el-input :placeholder="input_hold" v-model="textParam" @keyup.enter.native="stfgChange" size="small" style="width:320px;margin-left:10px;height:30px;margin-top:3px;">
                    <el-select v-model="oper" slot="prepend"   style="width:100px; " >
                        <span v-for="item in operArr" :key='item.IDSEQ'>
                            <el-option :label="item.CNAME" :value="item.SUBNO"></el-option>
                        </span>
                    </el-select>
                    <el-button  slot="append" icon="el-icon-search" @click="stfgChange" ></el-button>
                </el-input> 
                <i  class="iconfont icon-xuanze" style="font-size:12px;margin-left:10px;margin-right:10px"><span style="margin-left:5px;color:#606266">未审核</span></i>
                <i  class="iconfont icon-xuanze" style="color:green;font-size:12px;margin-right:10px; "><span style="margin-left:5px;color:#606266">已审核</span></i>
                <i  class="iconfont icon-xuanze" style="color:red;font-size:12px;margin-right:10px; "><span style="margin-left:5px;color:#606266">已作废</span></i>
            </div>
        </div>  
        <el-table  :data="poData" 
              border                            
              size="small"
              v-loading="loading"
              element-loading-text="加载中"
              element-loading-spinner="el-icon-loading"
              element-loading-background="rgba(255, 251, 255, 0.8)"
              :height="subHeight" 
              :row-key="getRowKey"
              ref="refreq"
              highlight-current-row
 
              style="overflow:auto;margin-top:1px;"> 
            <span v-for="(item ) in poCol" :key="item.key" > 
                <el-table-column  v-if="item.thide==='N' && item.key!=='STFG'"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    sortable
                    :min-width="item.width">
                </el-table-column> 
                <el-table-column  v-if="item.key==='STFG'"
                    :prop="item.key"
                    :label="item.title"
                    show-overflow-tooltip
                    :align="item.align"                                  
                    :min-width="item.width">
                    <template width="60" slot-scope="scope" >
                        <i v-if="scope.row.STFG==='Y'" class="iconfont icon-xuanze" style="color:green"></i>
                        <i v-if="scope.row.STFG==='N'" class="iconfont icon-xuanze"  ></i>
                        <i v-if="scope.row.STFG==='X'" class="iconfont icon-xuanze" style="color:red"></i>
                    </template>
                </el-table-column>  
            </span> 
             <el-table-column
                fixed="right"
                label="状态操作"
                align="center"
                width="255">
                <template slot-scope="scope">
                    <el-button size="mini"   type="text" icon="iconfont icon-chazhao" @click="editRec(scope.row,'Y',scope.$index)" style="margin-right:5px;">查看</el-button>
                    <el-button size="mini" v-if="scope.row.STFG!=='Y'"  type="text" icon="iconfont icon-bianji" @click="editRec(scope.row,'N',scope.$index)" style="margin-right:5px;">编辑</el-button> 
                    <el-button size="mini" v-if="scope.row.STFG==='N'"  type="text" icon="iconfont icon-shenhe" @click="stfgRec(scope.row,'Y')">审核</el-button> 
                    <el-button size="mini" v-if="scope.row.STFG==='Y'"  type="text" icon="iconfont icon-fanshenhe" @click="stfgRec(scope.row,'N')">反审核</el-button> 
                    <el-button size="mini"   type="text" icon="iconfont icon-shanchu" @click="stfgRec(scope.row,'X')">作废</el-button>   
                </template>
            </el-table-column>  
        </el-table>
        <!-- 分页查询 -->
        <div style="display:flex; line-height:35px;align-items:center;border:1px solid #ccc;padding-left:10px;background:white" >              
            <Page :total="dataCount" :page-size="rowSize" show-total :current="current" @on-change="changePage"></Page>
            <span style="color:#606266;margin:7px 10px;font-size:12px;">每页</span>
            <el-select v-model="rowSize" size="small" style="width:70px;" @change="initPage">
                    <el-option 
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                    </el-option>
            </el-select>
            <span style="color:#606266;margin:7px 10px;font-size:12px;">跳至</span>
            <el-input v-enter-number size="small" v-model="goPage" style="width:50px;"></el-input> 
            <span style="color:#606266;margin:7px 5px;font-size:12px;">页</span>
            <el-button size="small" @click="goNext" type="primary" style="width:50px;margin-left:15px;height:30px;">GO</el-button>
 
        </div>
        <!-- 提示框 -->
        <el-dialog :show-close="false" :visible.sync="promptWin" width="400px" append-to-body :close-on-click-modal="false" :close-on-press-escape="false" >
            <div slot="title" :style="{'background':'#409eff','color':'white','height':'35px'}">
                <span style="line-height:35px;height:35px;font-size:14px;padding-left:5px;">提示</span>
            </div>
            <div style=" margin:15px 10px">
                {{prompt}}
            </div>
            <div slot="footer">
                <Button type="primary"   @click="promptWin=false" style="margin-right:20px;">确定</Button>
            </div>
        </el-dialog>
        <!-- 新增、编辑表单 -->
        <el-dialog class="pub_dialog"  :show-close="false" :visible.sync="addWin" width="1200px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="4vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{addFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <div style="height:88vh;overflow-y:auto;padding-bottom:100px;">
                <Form :model="poRow" :label-width="110" ref="dtlform"  label-position="right" inline  :rules="ruleValidate">  
                    <span v-for="(item,index) in poCol" :key="index" >
                        <FormItem   :label="item.title"  class="item-width" v-if="item.fhide==='N'" :prop="item.key"  >                  
                            <el-date-picker :disabled="saveFlag" type="date"  v-if="item.edcss==='date'" v-model="poRow[item.key]" clearable value-format="yyyy-MM-dd" style="width:100%" ></el-date-picker>   
                            <el-input-number :disabled="saveFlag" :precision="item.dotnum"  v-else-if="item.edcss==='number'" v-model="poRow[item.key]" style="width:100%" ></el-input-number>   
                            <el-input type="text" v-else-if="item.key==='CLNTNO'" readonly v-model="poRow[item.key]">
                                <div slot="append">
                                    <el-button @click="orgChoose" icon="iconfont icon-icon-choose" style="width:50px;color:blue;font-weight:600;font-size:22px;"></el-button>
                                </div>
                            </el-input>
                            <el-select  v-else-if="item.key==='STKNO'" v-model="poRow[item.key]"  style="width:100%" filterable>
                                <el-option v-for="(item,index) in stkArr" :key="index" :value="item.SUBNO" :label="item.CNAME" ></el-option>
                            </el-select> 
                            <el-input :disabled="saveFlag ||item.disabled==='Y'?true:false" type="text" v-else   v-model="poRow[item.key]" ></el-input>
                        </FormItem>
                    </span>
                </Form>
                <div style="color:#fff;line-height:35px;height:35px;background:#409EFF;padding-left:5px; ">出库明细
                    <span style="float:right">
                        <i class="iconfont icon-xiazai" style="color:white;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                        <el-link :disabled="addBool||saveFlag" @click="refOrd" :underline="false" style="color:white"><span style="font-size:13px;margin-right:15px;">引用销售订单</span></el-link>  
                        <i class="iconfont icon-xinzeng" style="color:white;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                        <el-link :disabled="addBool||saveFlag" @click="addDtl" :underline="false" style="color:white"><span style="font-size:13px;margin-right:15px;">新增明细</span></el-link>   
                    </span>
                </div>
                <el-table  :data="dtlData" 
                    border                            
                    size="small"
                    :height="dtlHeight" 
                    :row-key="getDtlKey"
                    ref="refdtl"
                    highlight-current-row > 
                    <span v-for="(item,index ) in dtlCol" :key="index" > 
                        <el-table-column  v-if="item.thide==='N'"
                            :prop="item.key"
                            :fixed="item.key==='RN'"
                            :label="item.title"
                            show-overflow-tooltip
                            :align="item.align"                                  
                            sortable
                            :min-width="item.width">
                        </el-table-column>    
                    </span> 
                    <el-table-column
                        fixed="right"
                        label="操作"
                        align="center"
                        width="160">
                        <template slot-scope="scope">
                            <el-button size="mini" :disabled="saveFlag"  type="text" icon="iconfont icon-bianji" @click="dtlEdit(scope.row,scope.$index )">编辑</el-button>
                            <el-button size="mini" :disabled="saveFlag"  type="text" icon="iconfont icon-shanchu" @click="dtlDel(scope.row,scope.$index )">删除</el-button>  
                        </template>
                    </el-table-column>  
                </el-table>
 
            </div>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; " >
                <i class="iconfont icon-shanchu" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                <el-link  @click="addWin=false" :underline="false" style="margin-top:0px;"><span style="font-size:13px;margin-right:15px;">取消</span></el-link>
                <i class="iconfont icon-baocun" style="color:#409EFF;font-size:14px;margin-right:5px;margin-left:8px;"></i>
                <el-link @click="saveRec" :disabled="addBool||saveFlag"  :underline="false"  ><span style="font-size:13px;margin-right:25px;">保存</span></el-link>                 
                <i class="web-font" style="font-size:14px;margin-right:20px;color:#ed4014;font-weight:600"> 提示：订单审核后不能再编辑 , 如需编辑请反审核</i> 
                <i v-if="poRow['STFG']==='N'" style="margin-left:20px; color:red;font-size:28px; " class="iconfont icon-weishenhe3"> </i>
                <i v-else-if="poRow['STFG']==='Y'" style="margin-left:20px; color:red;font-size:28px; " class="iconfont icon-yishenhe1"> </i>
                <i v-else-if="poRow['STFG']==='X'" style="margin-left:20px; color:red;font-size:28px; " class="iconfont icon-yizuofei"> </i>
            </div>
        </el-dialog>
        <!-- 选择客户 -->
        <el-dialog :visible.sync="cltWin" width="1200px" append-to-body  :close-on-click-modal="false" :close-on-press-escape="false" top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">选择客户</span>
            </div> 
            <div style="height:88vh;overflow:auto"><cltInfo  @child-clnt="cltSel"></cltInfo></div>
        </el-dialog>
 
        <!-- 选择商品 -->
        <el-dialog class="pub_dialog" :visible.sync="selPrdWin" width="1300px" append-to-body  :close-on-click-modal="false" :close-on-press-escape="false" top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">选择商品</span>
            </div> 
            <prdInfo  @child-prd="prdSel"></prdInfo>
        </el-dialog>
        <!-- 选择销售订单 -->
        <el-dialog class="pub_dialog" :visible.sync="ordWin" width="1200px" append-to-body  :close-on-click-modal="false" :close-on-press-escape="false" top="2vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">选择销售订单</span>
            </div> 
            <div style="height:90vh;overflow-y:auto"><ordInfo  @child-sure="ordSel"></ordInfo></div>
        </el-dialog>
        <!--  明细编辑 -->
        <el-dialog  :show-close="false" :visible.sync="dtlWin" width="1200px" :close-on-press-escape="false" :close-on-click-modal="false" append-to-body top="1vh">
            <div slot="title" :style="{'background':'#67c23a','color':'white','height':'40px'}">
                <span style="line-height:40px;height:40px;font-size:16px;padding-left:15px;">{{dtlFlag==='create'?'新增记录':'编辑记录'}}</span>
            </div> 
            <Form :model="dtlRow" :label-width="120"  label-position="right" inline :rules="dtlValidate">  
                <span v-for="(item,index) in dtlCol" :key="index" >
                    <FormItem  :label="item.title"  :class=" 'item-width'" :prop="item.key"  v-if="item.fhide==='N'">                  
                        <el-checkbox border v-if="item.edcss==='checkbox'" true-label="Y"  false-label="N" v-model="dtlRow[item.key]" style="width:100%"></el-checkbox> 
                        <el-input type="text" v-else-if="item.key==='MATNO'" disabled v-model="dtlRow[item.key]">
                            <div slot="append">
                                <el-button @click="prdChoose" icon="iconfont icon-icon-choose" style="width:50px;color:blue;font-weight:600;font-size:22px;"></el-button>
                            </div>
                        </el-input>
                        
                        <el-input-number v-else-if="item.edcss==='number'"  v-model="dtlRow[item.key]" style="width:100%"></el-input-number>  
                        <el-input  type="text" v-else :disabled="item.disabled==='Y'?true:false"  v-model="dtlRow[item.key]"></el-input>
                    </FormItem>
                </span>
            </Form>
            <div style="height:40px;line-height:30px;border-top:2px solid #ccc;padding-top:4px; ">
                <el-button type="text" icon="iconfont icon-shanchu"  @click="dtlWin=false" style="margin-right:50px;">取消</el-button>
                <el-button type="text" icon="iconfont icon-baocun" @click="dtlAppend"  style="margin-right:10px;">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {getBaseData,getTabColOrData} from '../../api/user'; 
import {paramData,getDate} from '../../api/Select' 
import cltInfo from '../datamgt/clnt_tmpl.vue'
import prdInfo from '../datamgt/product_tmpl.vue'
import ordInfo from '../datamgt/ord_tmpl.vue'

export default {
    name:'stk_saleout',
    data () {
        return {
            dtlRow:{},
            ordWin:false,
            dtlWin:false,
            cltWin:false,
            selPrdWin:false,
            stkArr:[],
            promptWin:false,
            dtlFlag:'create',
            prdRow:{},
            supFlag:'',
            addFlag:'create',
 
            rowDtl:{},
            delPrdArr:[],
            stfgSet:'ALL',
            delDtlArr:[],
            prompt:'',
            input_hold:'请输入内容...',
            ruleValidate:{},
            dtlValidate:{'MATNO':[{ required: true, message: '请输入产品编码', trigger: 'blur' }],
                        'ACTQTY':[{ required: true, message: '收货数量', trigger: 'blur' }]},
            selparam:'',
            textParam:'',
            poRow:{},
            prdFlag:'create',
            goPage:'',
            rowIdseq:'',
            poData:[],
            cfmBool:false, //审核 权限
            addBool:false, //新增 权限
            editBool:false, //编辑权限
            cancelBool:false, //作废权限
            loading:false,
            current:1,
            saveFlag:false,
            allList:[], //询价单集合
            dtlData:[],
            matData:[],
            addWin:false,
            v_username :this.$cookies.get('v_username' ), //用户名称 
            v_password:this.$cookies.get('v_password') ,//api 请求密码
            v_url:this.$store.state.queryUrl , //api请求路径   
 
            options: [{
                value: 5,
                label: '5'
                }, {
                value: 10,
                label: '10'
                }, {
                value: 20,
                label: '20'
                }, {
                value: 50,
                label: '50'
            }],
            dataCount:0,
            rowSize:10,
            dtlCol:[],
            matCol:[],
            poCol:[],
            operArr:[],
            prcCol:[],
            prcData:[],
            prdIndex:'',
            oper:'like',
            colory:'#959997', //审核颜色
            colorx:'#e00e66', //作废颜色
            v_idseq:this.$cookies.get('idseq'),
            dtlIndex:'',
        }
    },
    components: {
      cltInfo,  //注册组件
      prdInfo,  //产品列表
      ordInfo, //采购订单
    },
    computed: {
        subHeight: function() {
            return (window.innerHeight - 190) + 'px';
        }, 
        dtlHeight: function() {
            return (window.innerHeight - 520) + 'px';
        }, 
    },
    mounted () {
        //操作符
        paramData('PARENTNO','41113','V_SYS_BASE').then((res)=>{
            this.operArr =res.data.result
        });  
        //税率
        paramData('PARENTNO','19992','V_SYS_BASE').then((res)=>{
            this.stkArr =res.data.result
        });
         //用户权限表
        getBaseData(this.$store.state.nologinUrl, 'idseq',this.v_idseq,'frmidseq','38','v_sys_user_priv','').then(res=>{
            this.privArr=res.data.result
            if (this.privArr[0].ISCFM==='N'){ //审核
                this.cfmBool=true
            }if (this.privArr[0].ISADD==='N'){ //新增 ,部门为供应商时不能新增
                this.addBool=true
            }if (this.privArr[0].ISUPD==='N'){ //编辑
                this.editBool=true
            }if (this.privArr[0].ISCANCEL==='N'){ //作废
                this.cancelBool=true
            }
        }) 
         //动态生成表单验证规则
        getTabColOrData(this.v_url,this.v_username,this.v_password,'v_sys_form_fld','idseq','38','FLDTY','HEAD','NOTNULL','Y').then((res) => {
            let ruleObj={}
            let ruleVal=[]
            this.notNull = res.data.result
            res.data.forEach(item => {
                let v_fldnm =item.FLDNM
                let obj1={required:true,message:v_fldnm+'不能为空',trigger: 'blur'}
                ruleVal =[]
                ruleVal.push(obj1)
                ruleObj[item.FLDNO]=ruleVal                  
            });
            this.ruleValidate =ruleObj
        });
    },
    created () {
        if (this.v_username){
            this.getTabCol() //表列集合
            this.getPoList('') //询价单主数据    
        }else{
            let routeUrl = this.$router.resolve({
                path: '/',
                query: {lang:this.LANG },
            });
            window.open(routeUrl.href, '_self'); 
        }
    },
    methods: {
        //销售订单选择
        ordSel(data_){
            if (data_.length>0){ //IDNO,MATNM,MATDESC,MODELNM,MATTYNM,PRC,TTLAMT,QTY ::TAXPRC,AMT
                for(let k=0;k<data_.length;k++){
                    let obj_={}
                    Object.assign(obj_,{MATNO:data_[k].IDNO,MATNM:data_[k].MATNM,MATDESC:data_[k].MATDESC,IDSEQ:this.rowIdseq,LSTSEQ:'',
                    MATTYNM:data_[k].MATTYNM,POQTY:data_[k].QTY,ACTQTY:data_[k].QTY,REFIDNO:data_[k].IDNO,REFIDSEQ:data_[k].IDSEQ,REFLSTSEQ:data_[k].LSTSEQ})
                    this.dtlData.push(obj_)
                }   
            }
            this.ordWin=false
        },
        refOrd(){
            this.ordWin =true
        },
        prcVal(){
            let tax_=Number(this.dtlRow['TAX'])
            let qty_=Number(this.dtlRow['QTY'])
            let taxprc_=Number(this.dtlRow['TAXPRC'])
            this.dtlRow['AMT'] =taxprc_ *qty_
            this.dtlRow['TAXAMT'] =taxprc_*tax_/100*qty_
        },
        //客户选择
        orgChoose(){
            this.cltWin=true
        },
        dtlEdit(row,index){
            this.dtlWin=true
            this.dtlRow=JSON.parse(JSON.stringify(row))
            this.dtlIndex=index
            this.rowDtl=row
            this.dtlFlag='edit'
        },
        //出库明细 
        getDtlList(idseq ){
            this.dtlData=[]
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_IC_STKdtl','idseq',idseq,'','','','','' ).then((res) => { 
                this.dtlData =res.data 
            })
            this.$nextTick(() => { //有固定列时 查询后重新布局
                this.$refs.refdtl.doLayout();
            }) 
        },
        //选择产品
        prdChoose(){
            this.selPrdWin=true
        },
 
        prdSel(matno,matnm,mattynm,modelnm,matdesc){
            this.dtlRow['MATNO']=matno
            this.dtlRow['MATNM']=matnm
            this.dtlRow['MATTYNM']=mattynm
            this.dtlRow['MODELNM']=modelnm
            this.dtlRow['MATDESC']=matdesc
            this.selPrdWin=false
        },
 
        //明细编辑、删除
        dtlDel(row,index){
            this.delDtlArr.push(row)
            this.dtlData.splice(index,1 )
        },
 
        //明细选择确认
        dtlAppend(){
            if(!this.dtlRow['MATNO']){
                this.promptWin=true
                this.prompt='产品编码不能为空'
            }else if(!this.dtlRow['ACTQTY']){
                this.promptWin=true
                this.prompt='出货数量不能为空'
            }else{
                if (this.dtlFlag==='create'){
                    this.dtlData.push(this.dtlRow)
                }else{
                    this.dtlData.splice(this.dtlIndex,1,Object.assign(this.rowDtl,this.dtlRow) )
                }
                this.dtlWin=false
            }
        },
        addDtl(){
            this.dtlFlag='create'
            this.dtlWin=true
            this.dtlRow={}
            this.delDtlArr=[]
        },
        //子件客户选择
        sup_Selrec(){
            this.cltWin=true
        },
        cltSel(idseq,val){
            this.poRow['CLNTNO']=idseq
            this.poRow['CLNTNM']=val
            this.cltWin=false
        },
        //编辑记录
        editRec(row,val,index){
            if (val==='Y'){  //仅查看
                this.saveFlag=true
            }else{
                if (row.STFG==='Y'){
                    this.saveFlag=true
                }else{
                    this.saveFlag=false
                }   
            }
            this.addFlag='edit'
            this.poRow =JSON.parse(JSON.stringify(Object.assign(row,{IDSEQ:row.IDSEQ})))
            this.rowIdseq=row.IDSEQ
            //出库明细
            this.getDtlList(row.IDSEQ)
            this.addWin=true      
        },
        //保存记录
        saveRec(){
            //发起保存请求   
            this.$refs['dtlform'].validate((valid) => {
                if (valid) {
                    if (this.poRow['CLNTNO']){
                        this.$axios({
                            method: 'post',
                            url:this.$store.state.baseUrl+'sysprivs/formOneSave?username='+this.v_username+'&password='+this.v_password,
                            data: {p_json:JSON.stringify(this.poRow), p_table:'IC_STK',frmid:'38'},
                        }).then(res=>{      
                            if(res.data.result ==='OK')  {  
                                let idseq_ =res.data.idseq
                                //1、出库明细保存：批量保存   
                                this.$axios({   
                                    method: 'post',
                                    url:this.$store.state.baseUrl+'sysprivs/batchBodySave?username='+this.v_username +'&password='+this.v_password,
                                    data: {p_json:JSON.stringify(this.dtlData), p_table:'IC_STKDTL',p_idseq:idseq_ },
                                }).then(res=>{
                                    if (res.data.code!=='200'){
                                        this.promtWin=true
                                        this.prompt=res.data.result
                                    }
                                })
                                //出库明细删除
                                for (let k=0;k<this.delDtlArr.length;k++) {
                                    this.$axios({   
                                        method: 'post',
                                        url:this.$store.state.baseUrl+'sysprivs/delTableRec?username='+this.v_username +'&password='+this.v_password,
                                        data: {p_key:'idseq',p_value:this.delDtlArr[k].IDSEQ,p_key2:'lstseq',p_value2:this.delDtlArr[k].LSTSEQ, p_table:'IC_STKdtl'},
                                    })
                                } 
            
                                this.addWin =false   
                                this.getPoList('')
                            }else{
                                this.promptWin=true
                                this.prompt=res.data.result
                            }        
                        }) 
                    }else{
                        this.promptWin=true
                        this.prompt='客户不能为空'
                    }
                }
            })
        },
        //新增
        addRec(){
            this.addWin=true
            this.poRow={ICDTTM:getDate('day'),'IDSEQ':'',IDTY:'SALEOUT'}
        },
        //表行记录css
        tableRowClass({row, rowIndex}) {
            if (row.STFG  === 'Y') {
                return 'success-row';
            } else if (row.STFG ==='X') {
                return 'cancel-row';
            }
            return '';
        },
        getRowKey(row){
            return row.IDSEQ
        },
        getDtlKey(row){
            if (row.IDSEQ && row.LSTSEQ)
            return row.IDSEQ+'-'+row.LSTSEQ
        },
 
        //状态变更
        stfgRec(row,val){
            let v_comm=''
            if (val==='Y'){
                v_comm='审核'
            }else if (val==='N'){
                v_comm='未审核'
            }else if (val==='X'){
                v_comm='作废'
            }
            this.$confirm('你确定要'+v_comm+'此单据吗?','提示',{
                confirmButtonText: '确定',
                cancelButtonText:'取消',
                customClass:'messageBox',
                showClose:false,
                type:'info',
            }).then(()=>{
                var vm=this;
                this.$axios({
                    method: 'post',
                    url:vm.$store.state.baseUrl+'user/updateRec?username='+vm.v_username+'&password='+vm.v_password,
                    data: {p_key:'idseq',p_value:row.IDSEQ,p_table:'IC_STK',p_cond:' set stfg=\''+val+'\' '},
                }).then(res=>{      
                    if(res.data.code =='200')  {           
                        Object.assign(row,{STFGNM:v_comm ,STFG:val})
                    }else{
                        if (res.data.result.indexOf('ORA-04088')>-1){
                            this.prompt='库存数量不足，请核查'
                        }else{
                            this.prompt=res.data.result
                        }
                        this.promptWin =true
                        
                    }        
                })
            }).catch(()=>{})
             
        },
 
 
        //状态查询
        stfgChange(){
            let cond_=''
            if (this.selparam){
                if (this.oper==='like'){
                    cond_=cond_+' and '+this.selparam+' like \'%'+this.textParam+'%\''
                }else{
                    cond_=cond_+' and '+this.selparam+this.oper+'\''+this.textParam+'\''
                }
            }
            if (this.stfgSet!=='ALL'){
               cond_=cond_+' and ordstfg=\''+this.stfgSet+'\''
            }
            this.getPoList(cond_)
        }, 
        goNext(){
            if (this.goPage){
                this.changePage(this.goPage)
                this.current =Number(this.goPage)
            }else{
                this.$alert('请输入跳至页数', '提示', {
                    confirmButtonText: '确定',
                    showClose:false,
                    type:'info'
                })
            }
        },
        //表格页面导航
        changePage(index){
            var _start = ( index - 1 ) * this.rowSize;
            var _end = index * this.rowSize;
            this.poData = this.allList.slice(_start,_end);
        },
        //初始化查询页条数
        initPage(){
            //产品明细
            this.dataCount =this.allList.length //总条数
            // 初始化显示，小于每页显示条数，全显，大于每页显示条数，取前每页条数显示
            if (this.allList.length < this.rowSize){
                this.poData = this.allList          
            }else{
                this.poData =this.allList.slice(0,this.rowSize) //返回选定元素的子数组，不包含结尾元素
            }
            this.$nextTick(() => { //有固定列时 查询后重新布局
                this.$refs.refreq.doLayout();
            }) 
        },
 
        //报价单 
        getPoList(cond){
            let vm =this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_IC_STK','IDTY','SALEOUT','','','','',cond ).then((res) => { 
                this.allList =res.data 
                this.initPage() 
            })
        },
 
        //报价商品 
        getMatList(idseq){
            getTabColOrData(this.v_url,this.v_username,this.v_password,'v_IC_STKdtl','idseq',idseq,'','','','','' ).then((res) => { 
                this.matData =res.data 
                if (res.data.length>0){
                    this.$refs['refmat'].setCurrentRow(res.data[0])
                }else{
                    this.prcData=[]
                }
            })
        },
        //获取表列头字段  v-enter-number
        getTabCol(){
            var vm=this
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','38','fty','HEAD','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.poCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE, 
                        fhide: res.data[k].FHIDE,                                               
                        disabled: res.data[k].DISABLED, 
                        edcss: res.data[k].EDCSS, 
                        dotnum: res.data[k].DOTNUM, 
                    })
                }
            })
            getTabColOrData(vm.v_url,vm.v_username,vm.v_password,'v_sys_form_fld','idseq','38','fty','HEAD2','','',' order by sortby,lstseq' ).then((res) => {
                for (let k=0; k<res.data.length; k++){
                    vm.dtlCol.push({ //向数组的开头 添加序列号
                        title: res.data[k].title,
                        key: res.data[k].key,
                        align: res.data[k].align,
                        width: res.data[k].width,  
                        thide: res.data[k].THIDE, 
                        fhide: res.data[k].FHIDE,                                               
                        disabled: res.data[k].DISABLED, 
                        edcss: res.data[k].EDCSS, 
                        dotnum: res.data[k].DOTNUM, 
                    })
                }
            })
 
        },
    }
}
</script>
<style scoped lang="less">
    .item-width  {
        width: 48%;
        color:#409EFF;
    }
    .pub_dialog {
        display: flex;
        justify-content: center;
        align-items: Center;
        overflow: hidden;
        .el-dialog {
            margin: 0 auto !important;
            height: 90%;
            overflow: hidden;
            .el-dialog__body {
                position: absolute;
                left: 0;
                top: 54px;
                bottom: 0;
                right: 0;
                padding: 0;
                z-index: 1;
                overflow: hidden;
                overflow-y: auto;
            }
        }
    }
</style>
<style lang="less">
    .el-table {
        th {
            padding: 0 ;
        }
        thead{
            height: 15px;
            color:rgb(11, 11, 59);
            font-size:12px;
            background:rebeccapurple;
        }
        td {
            padding: 5px ;
            font-size:11px;
        } 
    }
    .el-dialog__body{
        padding: 10px;
    }
    .el-dialog__header{
        padding: 0px;
    }
    .el-dialog__footer{
        padding: 5px;
        border-top:1px solid #ccc;
    } 
    .el-dialog__headerbtn{font-size: 20px; background:white;top:10px !important;}
 
    .el-table .success-row {
        color:#959997;
    }
    .el-table .cancel-row {
        color: #e00e66;
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after{
        border-color :#3d77eb;
    }
    .el-checkbox__input.is-disabled+span.el-checkbox__label{
        color:#3d77eb
    }
</style>